<template>
	<div class="page-content">
		<div style="display: flex;">
			<div style="width: 70%;width: 70%;border: solid 1px #eeeeee;padding: 20px;">
				<div style="color: #6b57a5;font-size: 18px;text-align: justify;margin-top: 20px;font-weight: bold;">
					China Association for Sience and Technology Released 30 Major Issues of Frontiers in Science,
					Engineering Technology and Industrial Technology in 2021.
				</div>
				<div style="margin-top: 20px;">
					<el-divider direction="vertical" style="width: 2px;background-color:#333333" /><span
						style="color: #666666;">June 1, 2022</span>
				</div>
				<div style="color: #333333;font-size: 18px;text-align: justify;margin-top: 20px;">
					On July 28th 2021, China Association for Science and Technology released 10 issues in Frontiers of
					Science
					Research as a guiding role, 10 issues in Frontiers of Engineering Technology Research as a pivotal
					role of
					innovation, and for the first time released 10 issues in Frontiers of Industrial Technology Research
					which
					leads the development of the industry.
				</div>

				<div style="color: #6b57a5;font-size: 18px;text-align: justify;margin-top: 60px;">
					10 issues in Frontiers of Science Research
				</div>

				<div style="color: #333333;font-size: 16px;text-align: justify;margin-top: 20px;">
					1. How to break through the preparation theory and technology of macro-crystalline materials?

				</div>

				<div style="color: #333333;font-size: 16px;text-align: justify;margin-top: 20px;">
					2. What is the mechanism of high-efficiency catalytic reaction under the nanometer scale?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					3. What is the environmental regulatory network from crop genes to phenotypes?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					4. Neutrino mass and cosmic matter--What is the origin of antimatter asymmetry?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					5. Is there a unified time rule outside the earth?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					6. How the memory in the brain is produced and reproduced?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					7. What is the path optimization and stabilization mechanism of the new power system with new energy
					as the
					main body?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					8. What is the physical mechanism of double-increasing effect in aluminum alloy Ultra-cryogenic
					deformation?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					9. How to reveal the dynamic mechanism of plate motion?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					10. What is the impact of the imbalance and instability in the "Asian Water Tower" on the river
					system of
					the Qinghai-Tibet Plateau?

				</div>


				<div style="color: #6b57a5;font-size: 18px;text-align: justify;margin-top: 60px;">
					10 issues in Frontiers of Engineering Technology Research
				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					1. How to use agricultural microbial germplasm resources efficiently?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					2. How to solve the problem of nanostructure measurement in 3D semiconductor chips?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					3. How to develop an solid-state secondary battery that doubles the specific energy?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					4. How to develop the key technology of China&apos;s own ultrahigh-resolution stereoscopic mapping
					satellites?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					5. How to use AI to realize the identification of multiple diseases in medical images and perform
					auxiliary
					diagnosis and treatment?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					6. How to break through the technical difficulties of deep sea voyage equipment manufacturing and
					safety
					assurance engineering?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					7. How to create a 5G+ full-cycle health management system?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					8. How to build an accurate and efficient ecological protection network and restore biodiversity
					through
					important ecosystem restoration projects?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					9. How to build a technical system for the expansion of carbon sinks in China&apos;s ecosystem?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					10. How to make a desktop-level micro-reactor battery?

				</div>


				<div style="color: #6b57a5;font-size: 18px;text-align: justify;margin-top: 60px;">
					10 issues in Frontiers of Industrial Technology Research
				</div>

				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					1. How to achieve precise photonic integration for large-scale integrated optical chips?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					2. How to develop medical implant materials for diseases of aging?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					3. How to develop a cavity surgery robot product that incorporates soft robotics and intelligent
					shadow
					control integration technology?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					4. How to develop large-scale low-energy-consumption liquid hydrogen technology and long-distance
					green
					hydrogen storage and transportation technology?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					5. How to solve the key technical problems of aero-engine nacelle in China?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					6. How to break through the targeted, rapid, economic and safe pollution abatement technology of
					heavy
					metals in cultivated land?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					7. How to use the scenic water to accelerate the realization of the "carbon neutral" goal?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					8. How to overcome the key technology research and development and engineering demonstration
					problems of
					floating offshore wind power?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					9. How to prepare high clean and high homogeneous superfine crystal high-end bearing steel material?

				</div>
				<div style="color: #333333;font-size: 14px;text-align: justify;margin-top: 20px;">
					10. How to develop a satellite Internet network communication technology integrated with 5G/6G?

				</div>

				<div style="font-size: 18px;text-align: justify;margin-top: 60px;">
					Since 2018, the China Association for Science and Technology has organized national societies and
					other
					science and technology communities to collect and select frontiers issues in science and engineering
					technology
				</div>

				<div style="font-size: 18px;text-align: justify;margin-top: 20px;">
					The China Association for Science and Technology will promote the establishment of a brand system
					for
					research and judgment on major issues and difficult problems, practice the value mission of the
					science and
					technology community, gather the consensus of scientific and technological workers, deepen global
					scientific
					and technological exchanges and cooperation, continue to study and judge scientific and
					technological
					development trends, identify key core technologies, and unite scientific and technological forces to
					coordinate research. Promote high-level technological self-reliance and self-reliance.
				</div>
			</div>
			<div style="width: 30%;">
				<div style="width: 360px;margin-left: 20px;background: rgb(243, 249, 252);height: 150px;padding: 15px;">
					<p style="padding-left: 20px;"><span style="font-size: 16px;color: #4b4d4d;margin-right: 10px;">
							<el-icon>
								<Share />
							</el-icon>
						</span>
						<span style="color: #4b4d4d;font-size: 16px;font-family: PingFang-SC-Medium;">Share this
							article with:</span>
					</p>
					<ul style="display: flex;margin: 10px 0 0 6px;list-style: none;">
						<li style="margin-right: 15px;" @click="facebook()">
							<el-image style="" :src="require('@/assets/images/2_03.png')"></el-image>
						</li>
						<li style="margin-right: 15px;" @click="twitter()">
							<el-image style="" :src="require('@/assets/images/twitter.png')"></el-image>
						</li>
						<li style="margin-right: 15px;" @click="linkedin()">
							<el-image style="" :src="require('@/assets/images/2_07.png')"></el-image>
						</li>
						<!-- <li @click="weChat()">
							<el-image style="" :src="require('@/assets/images/weixinlogo.png')"></el-image>
						</li> -->
					</ul>
					<p style="padding-left: 20px;padding-top: 15px;"><span
							style="font-size: 16px;color: #4b4d4d;margin-right: 10px;">
							<el-icon>
								<Share />
							</el-icon>
						</span>
						<span style="color: #4b4d4d;font-size: 16px;font-family: PingFang-SC-Medium;">Forward to a
							Friend:</span>
					</p>
				</div>
				<div class="rightData">
					<p style="font-size: 20px;">
						Measuring Citing Nubmers
					</p>
					<el-divider style="height: 2px;width: 355px;margin-top: -12px;" />
					<el-divider style="height: 2px;width: 125px;margin-top: -26px;background-color: #6b57a5;" />
					<div>
						<div v-for="(item,itemIndex) in journalList" :key="itemIndex">
							<p class="two">{{item.ti}}
							</p>
							<p><span style="color: #b0b0b0;">{{item.pd}} {{item.py}}</span>
								<el-link :underline='false' href="javascript:void(0)" @click="details(itemIndex)"
									style="color: #fe7568;float: right;">
									Read more>
								</el-link>
							</p>
						</div>

					</div>

				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				journalList: [],
			};
		},

		methods: {
			facebook() {
				this.$notify.success({
					title: "success",
					message: "facebook share success!",
				});
				var u = "www.sciencealerts.org";
				var t = "sciencealerts";
				window.open("http://www.facebook.com/sharer.php?u=" + encodeURIComponent(u) + "&t=" + encodeURIComponent(
					t), "sharer", "toolbar=0,status=0,width=600, height=450,top=200,left=550");

			},
			twitter() {
				this.$notify.success({
					title: "success",
					message: "twitter share success!",
				});
				var twTitle = 'sciencealerts';
				var twUrl = this.Escape("www.sciencealerts.org");
				window.open('http://twitter.com/intent/tweet?url=' +
					encodeURIComponent(twUrl) +
					"&text=" +
					encodeURIComponent(twTitle),
					"_blank",
					"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
				)

			},
			linkedin() {
				this.$notify.success({
					title: "success",
					message: "linkedin share success!",
				});
				var lt = 'sciencealerts';
				var lu = this.Escape("www.sciencealerts.org");
				window.open('https://www.linkedin.com/shareArticle?mini=true?&title' +
					encodeURIComponent(lt) +
					"&url=" +
					encodeURIComponent(lu),
					"_blank",
					"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
				)

			},
			weChat() {
				this.$notify.success({
					title: "success",
					message: "weChat share success!",
				});
			},
			randomQueryJournal() {
				let _this = this
				let paramMap = {
					num: 10
				}
				$http.post("randomQueryJournal", paramMap, "rightData")
					.then(function(res) {
						_this.journalList = res.data;
					})
					.catch(function() {});
			},
			details(index) {
				this.$router.push({
					name: "details",
					params: this.journalList[index]
				});
			},
		},
		created() {
			this.randomQueryJournal()
		},
		mounted() {

		},

	};
</script>

<style lang="scss" scoped>
	.two {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.rightData {
		width: 360px;
		margin-left: 20px;
		background: #fafafa;
		margin-top: 40px;
		padding: 15px;
	}

	ul>li:hover {
		cursor: pointer;
	}
</style>
